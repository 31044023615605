import React from "react";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import "../components/SolutionBox.scss";

function SolutionBox(props) {
  const Bold = ({ children }) => <strong>{children}</strong>;
  const Text = ({ children }) => <p>{children}</p>;
  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
      [BLOCKS.LIST_ITEM]: (node, children) => {
        const UnTaggedChildren = documentToReactComponents(node, {
          renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => children,
            [BLOCKS.LIST_ITEM]: (node, children) => children,
          },
        });

        return <li>{UnTaggedChildren}</li>;
      },
    },
  };

  const alignment = props.index % 2 ? "left" : "right";

  return (
    <div className={alignment === "left" ? "solution-box" : "solution-box ml-auto"} id={props.anchor}>
      <div className="row">
        <div
          id={props.id}
          className={
            alignment === "left"
              ? "col-md-7 solution-box-texts order-md-2 left"
              : "col-md-7 solution-box-texts order-md-1 right"
          }
        >
          <h2>{props.title}</h2>
          {renderRichText(props.description, options)}
        </div>

        <div
          className={
            alignment === "left" ? "col-md-5 order-md-1 solution-box-img" : "col-md-5 order-md-2 solution-box-img"
          }
        >
          {props.image.file && <img src={props.image.file.url} alt="" className="img-fluid" />}
        </div>
      </div>
    </div>
  );
}

export default SolutionBox;
