import * as React from "react";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";

import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import SolutionBox from "../components/SolutionBox";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import WhatsappButton from "../components/WhatsappButton";

import { slugify } from "../utils/helpers";

import "../scss/main.scss";
import 'bootstrap/dist/css/bootstrap.min.css';

const SolucionesPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulSolution(sort: { fields: title_es, order: ASC }) {
        nodes {
          description_es {
            raw
          }
          title_es
          image {
            file {
              url
            }
          }
        }
      }
      allContentfulSolutionsPageTemplate {
        nodes {
          hero_title_es
          hero_description_es {
            hero_description_es
          }
          hero_image {
            file {
              url
            }
          }
        }
      }
    }
  `);

  const pageTitle = "Soluciones | Virtual Ed Global"
  const heroTitle = data.allContentfulSolutionsPageTemplate.nodes[0].hero_title_es;
  const heroDescription =
    data.allContentfulSolutionsPageTemplate.nodes[0].hero_description_es.hero_description_es !== "empty"
      ? data.allContentfulSolutionsPageTemplate.nodes[0].hero_description_es.hero_description_es
      : "";
  const heroImg = data.allContentfulSolutionsPageTemplate.nodes[0].hero_image.file.url;

  return (
    <main>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={heroTitle} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:site_name" content="Virtual Ed Global" />
        <meta property="og:url" content="https://virtualedglobal.com/soluciones" />
        <meta property="og:description" content={heroDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={heroImg} />
        <meta name="twitter:image" content={heroImg} />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={heroDescription} /> 
      </Helmet>

      <Navbar />

      <div className="page-wrapper">
        <HeroSection image={heroImg} title={heroTitle} description={heroDescription} />

        <section className="solution-boxes">
          {data.allContentfulSolution.nodes.map((item, index) => {
            return (
              <SolutionBox
                title={item.title_es}
                key={item.title_es}
                description={item.description_es}
                index={index}
                image={item.image}
                id={slugify(item.title_es)}
              />
            );
          })}
        </section>

        {/* Contact */}
        <section id="contacto">
          <Contact
            title={
              <p>
                <strong>Aquí estamos para conversar sobre Educación Superior.</strong> Esperamos tus consultas o
                comentarios.
              </p>
            }
            button_label="Conversemos"
          />
        </section>
      </div>

      <WhatsappButton />
      <Footer />
    </main>
  );
};

export default SolucionesPage;
